import dayjs from "dayjs";
import i18next from "i18next";
import DayjsAdapter from "@mui/lab/AdapterDayjs";
import React, { useState } from "react";
import { CalendarPickerSkeleton, DatePicker, LocalizationProvider, StaticDateRangePicker } from "@mui/lab";
import { Box, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSelector } from "react-redux";
import { getData } from "../../api/dataSlice";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function DateRangePicker({ isDisabled, value, onChange, error, onError, isLoading, siderWidth }) {
    
    const { isPortrait } = useMobileOrientation();
    const { source } = useSelector(getData);
    const { t } = useTranslation();

    const [predefinedSpan, setPredefinedSpan] = useState("");
    const [forceRefresh, setForceRefresh] = useState(true);
    
    const today = dayjs();
    const daySize = 34;
    const minDate = dayjs(source?.[0]?.[0]?.[0]);
    const maxDate = dayjs(source?.[0]?.[source[0].length - 1]?.[0]);

    const onChangeTimeSpanStartDate = (date) => {
        if (value) {
            setPredefinedSpan("");
            onChange([date, value[1]]);
        }
    };

    const onChangeTimeSpanEndDate = (date) => {
        if (value) {
            setPredefinedSpan("");
            onChange([value[0], date]);
        }
    };

    const onChangeTimeSpan = (dateRange) => {
        if (dateRange?.length) {
            onChange(dateRange);
            setPredefinedSpan("");
        }
    };

    function shouldDisableDate(date) {
        var dict = source?.[1];
        var hasDay = dict?.[date.$y]?.[date.$M]?.[date.$D];
        return hasDay ? false : true;
    }

    const onChangePredefinedSpan = (event, newPredefinedSpan) => {
        if (newPredefinedSpan) {
            switch (newPredefinedSpan) {
                case "day":
                    const yesterday = today.subtract(1, "day");
                    onChange([yesterday, yesterday]);
                    break;
                case "week":
                    const lastWeek = today.subtract(1, "week");
                    onChange([lastWeek?.day(1), lastWeek?.day(7)]);
                    break;
                case "month":
                    const lastMonth = today.subtract(1, "month");
                    onChange([lastMonth?.date(1), lastMonth?.endOf("month")]);
                    break;
                default:
                    break;
            }

            setPredefinedSpan(newPredefinedSpan);
            setForceRefresh(!forceRefresh); // force render
        }
    };

    return (
        <LocalizationProvider
            key={forceRefresh}
            dateAdapter={DayjsAdapter}
            locale={i18next.language}
        >

            {!isMobile ? (
                <Box sx={{ display: "flex" }}>
                    
                    <DatePicker
                        label={t("date.start_date")}
                        inputFormat={t("date.format.short")}
                        mask={t("date.mask.short")}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                sx={{
                                    width: "100%",
                                    userSelect: "none",
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": { borderColor: "#4caf50" },
                                    },
                                    "& .MuiInputAdornment-root": {
                                        margin: "-4px",
                                    }
                                }}
                            />
                        )}
                        onError={(reason) => {
                            if (reason === null) {
                                onError([null, error[1]]);
                            } else {
                                onError([reason, error[1]]);
                            }
                        }}
                        disableFuture={true}
                        value={value[0]}
                        onChange={onChangeTimeSpanStartDate}
                        cancelText={t("actions.cancel")}
                        toolbarTitle={t("date.select_date")}
                        minDate={minDate}
                        maxDate={value[1]}
                        loading={isLoading}
                        disabled={isDisabled}
                        renderLoading={() => <CalendarPickerSkeleton />}
                    />

                    <p style={{
                        paddingLeft: 12,
                        paddingRight: 12,
                        height: "100%",
                        alignItems: "center",
                        userSelect: "none",
                    }}>
                        {t("date.to")}
                    </p>

                    <DatePicker
                        label={t("date.end_date")}
                        inputFormat={t("date.format.short")}
                        mask={t("date.mask.short")}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                sx={{
                                    width: "100%",
                                    userSelect: "none",
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": { borderColor: "#ed6c02" },
                                    },
                                    "& .MuiInputAdornment-root": {
                                        margin: "-4px",
                                    }
                                }}
                            />
                        )}
                        onError={(reason) => {
                            if (reason === null) {
                                onError([error[0], null]);
                            } else {
                                onError([error[0], reason]);
                            }
                        }}
                        disableFuture={true}
                        value={value[1]}
                        onChange={onChangeTimeSpanEndDate}
                        cancelText={t("actions.cancel")}
                        toolbarTitle={t("date.select_date")}
                        minDate={value[0]}
                        maxDate={maxDate}
                        loading={isLoading}
                        disabled={isDisabled}
                        renderLoading={() => <CalendarPickerSkeleton />}
                    />
                </Box>
            ) : (
                <Box sx={{ display: "flex" }}>
                    <DatePicker
                        label={t("date.start_date")}
                        inputFormat={t("date.format.full")}
                        mask={t("date.mask.full")}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                sx={{
                                    width: "100%",
                                    userSelect: "none",
                                }}
                            />
                        )}
                        value={value[0]}
                        onChange={onChangeTimeSpanStartDate}
                        cancelText={t("actions.cancel")}
                        toolbarTitle={t("date.select_date")}
                        minDate={minDate}
                        maxDate={value[1]}
                        loading={isLoading}
                        disabled={isDisabled}
                        renderLoading={() => <CalendarPickerSkeleton />}
                        showTodayButton
                        todayText={t("date.today")}
                    />

                    <p style={{
                        paddingLeft: 12,
                        paddingRight: 12,
                        height: "100%",
                        alignItems: "center",
                        userSelect: "none",
                    }}>
                        {t("date.to")}
                    </p>

                    <DatePicker
                        label={t("date.end_date")}
                        inputFormat={t("date.format.full")}
                        mask={t("date.mask.full")}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                sx={{
                                    width: "100%",
                                    userSelect: "none",
                                }}
                            />
                        )}
                        value={value[1]}
                        onChange={onChangeTimeSpanEndDate}
                        cancelText={t("actions.cancel")}
                        toolbarTitle={t("date.select_date")}
                        minDate={value[0]}
                        maxDate={maxDate}
                        loading={isLoading}
                        disabled={isDisabled}
                        renderLoading={() => <CalendarPickerSkeleton />}
                        showTodayButton
                        todayText={t("date.today")}
                    />
                </Box>
            )}

            {!isMobile && (
                <Box sx={{
                    "& > div": {
                        minWidth: siderWidth,
                        pt: 2
                    },
                    "& > div > div": {
                        border: "1px solid",
                        borderColor: "#BDBDBD",
                        borderRadius: "4px",
                    },
                    "& > div > div, & > div > div > div, & > div > div > div > div": {
                        width: siderWidth - 2,
                        // minHeight: siderWidth + 24,
                    },
                    "& .MuiTypography-caption": {
                        width: daySize + 4,
                        margin: 0,
                    },
                    "& .PrivatePickersSlideTransition-root": {
                        minHeight: daySize * 7.2,
                        overflow: "hidden"
                    },
                    '& .PrivatePickersSlideTransition-root [role="grid"]': {
                        width: siderWidth,
                    },
                    // "& .MuiPickersDay-dayWithMargin": {
                    //     margin: 0,
                    // },
                    "& .MuiPickersDay-root": {
                        width: daySize,
                        height: daySize
                    }
                }}>
                    <StaticDateRangePicker
                        calendars={1}
                        displayStaticWrapperAs="desktop"
                        mask={t("date.mask.full")}
                        value={value}
                        onChange={onChangeTimeSpan}
                        renderInput={() => {}}
                        minDate={minDate}
                        maxDate={maxDate}
                        loading={isLoading}
                        disabled={isDisabled}
                        shouldDisableDate={shouldDisableDate}
                        renderLoading={() => <CalendarPickerSkeleton />}
                    />
                </Box>
            )}

            <ToggleButtonGroup
                color="primary"
                exclusive
                onChange={onChangePredefinedSpan}
                value={predefinedSpan}
                fullWidth
                orientation={isMobile ? "horizontal" : "vertical"}
                sx={{
                    py: 2,
                    height: isMobile && isPortrait ? "73px" : "auto",
                }}
            >
                <ToggleButton
                    disabled={
                        isLoading || isDisabled || today.subtract(1, "day") < minDate
                    }
                    value="day"
                >
                    {t("date.yesterday")}
                </ToggleButton>

                    <ToggleButton
                    disabled={
                        isLoading ||
                        isDisabled ||
                        today.subtract(1, "week").day(1) < minDate
                    }
                    value="week"
                >
                    {t("date.last_week")}
                </ToggleButton>

                <ToggleButton
                    disabled={
                        isLoading ||
                        isDisabled ||
                        today.subtract(1, "month").date(1) < minDate
                    }
                    value="month"
                >
                    {t("date.last_month")}
                </ToggleButton>
            </ToggleButtonGroup>
        </LocalizationProvider>
    );
};