import React, { useState, useRef } from 'react';
import { Box, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { fetchFile } from '../Utilities';
import { useDispatch, useSelector } from 'react-redux';
import { loadNextImage, loadPrevImage, setIndex, setOverviewCameraId, getData, setCheckedArchivePictures } from '../../api/dataSlice';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Title from "../Title"
import Controlbar from "../Controlbar"
import Image from "../Image"
import OverviewGrid from './OverviewGrid';

let imgHeight = 0;

export default function Archive(props) {

    const { breakpoints } = useTheme();
    const { t } = useTranslation()
    const { isLandscape } = useMobileOrientation()
    const { isSideDrawerOpen, contentHeight, camera: { name: cameraName = "", id: cameraId = 0, download: cameraUrl = "", image: fallbackSource }, imageSource, imagesList, imageIndex } = props
    const { timestamp } = useSelector(getData)
    const { CAMERAS, LAYOUT: { WRAP_LIMIT, TITLE } } = window.conf

    const [zoom, setZoom] = useState(1)
    const [showOverview, setShowOverview] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const fullscreenHandle = useFullScreenHandle()
    const camCount = CAMERAS.length
    const moreThanOneCam = camCount > 1
    const manyCams = camCount > WRAP_LIMIT
    const dispatch = useDispatch();
    const imgRef = useRef(null)
    const isXl = useMediaQuery(breakpoints.up('xl'))
    const title = TITLE ? `${TITLE}_` : ""
    const camera = cameraName
        ? `${cameraName}_` 
        : moreThanOneCam 
            ? `${t("events.camera")} ${cameraId + 1}_` 
            : ""
    const selectedDatetime = timestamp.format("YY-MM-DD_HH-mm")
    const targetFilename = `${title}${camera}${selectedDatetime}` || t("actions.screenshot")

    const handleOverviewClick = (resetTransform) => {
        resetTransform();        
        if (!showOverview) {
            imgHeight = imgRef.current.parentNode.offsetHeight
            setShowOverview(true);
            dispatch(setOverviewCameraId(cameraId));
        } else {
            setShowOverview(false);
            dispatch(setOverviewCameraId(null));
            dispatch(setCheckedArchivePictures({ type: 'clean' }))
        }
    }

    // Set use selected image index to the redux store to set the current camera imagea to the given index
    function onOverviewImageSelected(index, hideOverview) {
        // Get index of the user selected image
        if (Number.isFinite(index)) {
            dispatch(setIndex(index));
        }

        if (hideOverview) {
            setShowOverview(false);
            dispatch(setCheckedArchivePictures({ type: 'clean' }))
        }
    }
    
    const SwipeHandler = useSwipeable({
        onSwipedLeft: () => zoom === 1 ? dispatch(loadNextImage()) : null,
        onSwipedRight: () => zoom === 1 ? dispatch(loadPrevImage()) : null,
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    })

    // disables panning when image is not zoomed in or panned
    const PanningInteractionHandler = (instance, state) => {
        if (state?.positionX >= 0 && state?.positionY >= 0 && state?.scale <= 1) {
            instance.setup.panning.disabled = true;
        } else {
            instance.setup.panning.disabled = false;
        }
    }

    return (
        <TransformWrapper
            disablePadding // removes paddable border around the content
            onZoomStop={ref => PanningInteractionHandler(ref.instance, ref.state)}
            onPanning={ref => PanningInteractionHandler(ref.instance, ref.state)}
            onInit={ref => ref.instance.setup.panning.disabled = true}
            onTransformed={ref => setZoom(ref.state.scale)}
        >
            {(utils) => (
                <FullScreen 
                    handle={fullscreenHandle}
                    onChange={() => utils.resetTransform()}
                >
                    <Title cameraName={cameraName} />

                    <Box
                        {...SwipeHandler}
                        sx={{
                            display: showOverview ? 'none' : "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: fullscreenHandle.active
                                ? "100%"
                                : isMobileOnly
                                    ? isLandscape
                                        ? contentHeight
                                        : camCount < 2
                                            ? contentHeight
                                            : camCount < 3
                                                ? contentHeight/2
                                                : 'unset'
                                    : manyCams
                                        ? "99.5%"
                                        : isXl && camCount > 2
                                            ? contentHeight/Math.ceil(camCount/3)
                                            : contentHeight/Math.ceil(camCount/2)
                        }}
                        className={
                            isMobile
                            ? isLandscape && isSideDrawerOpen && moreThanOneCam
                                ? 'arch-mbl-height'
                                : ''
                            : camCount < 2
                                ? ''
                                : camCount === 2
                                    ? 'height-2'
                                    : 'height'
                        }
                    >
                        <TransformComponent>
                            <Image
                                ref={imgRef}
                                source={imageSource}
                                cursor={zoom > 1 ? 'move' : 'default'}
                                fallbackSource={fallbackSource}
                            />
                        </TransformComponent>
                    </Box>

                    {showOverview && (
                        <OverviewGrid 
                            imgHeight={imgHeight}
                            imagesList={imagesList}
                            imageIndex={imageIndex}
                            contentHeight={contentHeight}
                            cameraId={cameraId}
                            cameraUrl={cameraUrl}
                            fullscreenHandle={fullscreenHandle}
                            onImageSelected={onOverviewImageSelected}
                            showOverview={showOverview}
                            handleOverviewClick={() => handleOverviewClick(utils.resetTransform)}
                            fallbackSource={fallbackSource}
                        />
                    )}

                    <Controlbar
                        cameraName={cameraName}
                        zoom={zoom}
                        fullscreenHandle={fullscreenHandle}
                        zoomUtils={utils}
                        imageSource={imageSource}
                        showOverview={showOverview}
                        handleOverviewClick={() => handleOverviewClick(utils.resetTransform)}
                        hasOverview
                        handleTakeSnapshot={() => fetchFile(imageSource, `${targetFilename}.jpg`, setIsDownloading)}
                    />

                    {isDownloading && (
                        <Box sx={{
                            position: 'absolute',
                            width: '99%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bottom: 35
                        }}>
                            <Typography variant="caption" fontSize={16} color="white">
                                {t("actions.saving")}...
                            </Typography>
                            <LinearProgress style={{ width: '100%' }} />
                        </Box>
                    )}

                </FullScreen>
            )}
        </TransformWrapper>
    )
}