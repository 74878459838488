import React, { useEffect, useState, useRef, useCallback } from 'react';
import { grey } from '@mui/material/colors';
import { EventNote } from '@mui/icons-material';
import { Grid, Container, IconButton, SwipeableDrawer, Box, Toolbar, Drawer, useTheme, Backdrop } from '@mui/material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux"
import { setSource, getData, setIndex, loadNextImage, loadPrevImage, setTimestamp, setEvents, setInitialFooterHeight } from "../../api/dataSlice"
import { DateTimePicker, LocalizationProvider, TimePicker, CalendarPickerSkeleton } from '@mui/lab';
import { normalizeWeatherCode, useWindowDimensions } from "../Utilities"
import { useGetDataByCameraQuery, useLazyGetTagsByCameraQuery } from "../../api/graphqlApi";
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails } from '../Accordion';
import { useKeycloak } from '../Keycloak';
import Download from './Download'
import Calendar from './Calendar' 
import Events from './Events'
import TimeSelector from './TimeSelector'
import DayjsAdapter from '@mui/lab/AdapterDayjs';
import i18next from 'i18next';
import Diary from './Diary';
import DownloadButton from '../DownloadButton';
import dayjs from 'dayjs';

export default function Controls({ initialSiderWidth, onOpenSideDrawer, setPaddingFooter, drawerBleeding }) {
    
    const { CAMERAS, WEATHER_CATEGORY, DEBUG, LAYOUT: { DIARY, PROJECT_FILE_DATE, PROJECT_FINISHED }, BASEURL } = window.conf
    const { t } = useTranslation()
    const { source, index, timestamp, events } = useSelector(getData);
    const { isPortrait } = useMobileOrientation()
    const { data, isLoading: loading } = useGetDataByCameraQuery(null, { pollingInterval: 600000 })
    const { contentHeight, appbarHeight } = useWindowDimensions()
    const { isLoggedIn, isUsersPortal, hasRole } = useKeycloak()

    const [getTagsByCamera] = useLazyGetTagsByCameraQuery()
    const [canStepLeft, setCanStepLeft] = useState(false)
    const [canStepRight, setCanStepRight] = useState(false)
    const [footerOpen, setFooterOpen] = useState(false)
    const [footerHeight, setFooterHeight] = useState(48)
    const [firstRun, setFirstRun] = useState(true)
    const [pickerOpen, setPickerOpen] = useState(false)
    const [loadingEvents, setLoadingEvents] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [openTagModal, setOpenTagModal] = useState(false)
    const [tagData, setTagData] = useState()
    const [expanded, setExpanded] = useState("archive");
    const [siderWidth, setSiderWidth] = useState();
    const [animating, setAnimating] = useState(false);

    const refSider = useRef()
    const dispatch = useDispatch()
    const accordionOffset = 32; // 16px padding on each side
    const enableDiary = DIARY.ENABLED
    const enableDownloadTotal = PROJECT_FILE_DATE && PROJECT_FINISHED && isLoggedIn() && isUsersPortal() && hasRole("download")
    const minDate = dayjs(source?.[0]?.[0]?.[0])
    const maxDate = dayjs(source?.[0]?.[source[0].length-1]?.[0])
    const startYRef = useRef(0);
    const isPullingRef = useRef(false);
    const theme = useTheme();
    const closedFooterHeight = footerOpen
        ? events?.length
            ? footerHeight + 162 + events.length * 33 > contentHeight // higher than 80% of window height
                ? contentHeight - 20 // limit to content height
                : footerHeight + 162 + (events.length * 33) // depending on footer elements and data entrys
            : footerHeight + 189 // depending on footer elements (picker, divider, searchbar, no-data display)
        : footerHeight
    const fullHeight = isPortrait ? "100vh" : `calc(100vh - ${appbarHeight}px)`

    const onChangeExpanded = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    const onOpenTagModal = (row) => {
        setOpenTagModal(true)
        setTagData(row)
    }

    const onCloseTagModal = () => {
        setOpenTagModal(false)
        setTagData(null)
    }

    const toggleSideDrawer = (open) => {
        setIsDrawerOpen(open);
        onOpenSideDrawer(open);

        if (open) {
            document.body.style.overflow = 'auto'; // keep background (content) scrollable
        }
    };

    const toggleBottomDrawer = (component) => {
        if (component) {
            setFooterHeight(20)
            setExpanded(component)
            setFooterOpen(true)
        } else {
            setAnimating(true);

            setTimeout(() => {
                setFooterOpen(false);
                setAnimating(false);
            }, theme.transitions.duration.leavingScreen);
        }
    };

    const updateTimestamp = (timestamp) =>{
        dispatch(setTimestamp(timestamp));
    }

    // read tags from database - necessary to get consistent event list after CRUD operations by AddTag
    const readTags = useCallback(async() => {
        if (source && index) {
            try {
                var archivePictureByCamera = source[0]?.[index]?.[1]
                var archivePicturesDetailsByCamera = await getTagsByCamera({ archivePictureByCamera: archivePictureByCamera })
                var tags = 
                    archivePicturesDetailsByCamera?.data?.map((details, index) => 
                        details?.map(elem => {
                            let isWeather = elem.kategorie === WEATHER_CATEGORY
                            let category = isWeather ? t(`weather.weather`) : elem.kategorie
                            let description = isWeather ? t(`weather.conditions.${normalizeWeatherCode(elem.beschreibung)}`) : elem.beschreibung
                            let weather = elem.weatherArchives?.[0]

                            if (isWeather && !!weather) {
                                description = `${Math.round(weather.temp)}°, ${t(`weather.conditions.${normalizeWeatherCode(weather.weatherCode)}`)}, ${t(`weather.wind`)} ${weather.windSpeed} km/h, ${t(`weather.humidity`)} ${weather.humidity}%`
                            }

                            return ({ 
                                id: elem.id,
                                camera: CAMERAS[index], 
                                category: category,
                                description: description,
                                archivePicturesId: elem.archivePicturesId
                            })
                        })
                    ).flat().filter(elem => elem) // filter empty tags

                return tags ?? []

            } catch (error) {
                console.error(error)
            }
        }
    }, [index, source, getTagsByCamera]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadEvents = useCallback(async() => {

        setLoadingEvents(true)

        var tags = await readTags()

        if (tags) {

            var events = [...tags]
            var eventsHeight = (footerOpen ? 20 : 48 + (events.length <= 3 ? events.length : 3) * 29)
    
            if (DEBUG) {
                console.log("events", events)
            }
    
            dispatch(setEvents(events))
            dispatch(setInitialFooterHeight(eventsHeight))
            setFooterHeight(eventsHeight)
        } 
        
        setLoadingEvents(false)
    }, [readTags, dispatch, footerOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    function stepLeft() {
        if (Number.isFinite(index) && canStepLeft) {
            dispatch(loadPrevImage());
        } 
    }

    function stepRight() {
        if (Number.isFinite(index) && canStepRight) {
            dispatch(loadNextImage());
        } 
    }

    function shouldDisableDate(date) {
        var dict = source?.[1]
        var hasDay = dict?.[date.$y]?.[date.$M]?.[date.$D]
        return hasDay ? false : true
    }

    function shouldDisableTime(time, type) {
        var dict = source?.[1]
        switch (type) {
            case "hours":
                var hasHour = dict?.[timestamp?.$y]?.[timestamp?.$M]?.[timestamp?.$D]?.[time]
                return hasHour ? false : true
            case "minutes":
                var hasMinute = dict?.[timestamp?.$y]?.[timestamp?.$M]?.[timestamp?.$D]?.[timestamp?.$H]?.[time]
                return hasMinute ? false : true
            default:
                return false
        }
    }

    useEffect(() => {
        setPaddingFooter(closedFooterHeight)
    }, [closedFooterHeight, setPaddingFooter])

    useEffect(() => {
        loadEvents()
    }, [loadEvents])

    useEffect(() => {
        if (index) {
            updateTimestamp(source?.[0]?.[index]?.[0])
        }
    }, [index]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { 
        if (data) {
             if (firstRun) {
                var list = data[0]
                var lastIndex = list.length-1
                var lastElement = list[lastIndex]
                
                // set time in TimeSelector for display clock by date of last image (ONLY ONCE!)
                updateTimestamp(lastElement?.[0])
    
                // set index in redux store for Controls
                dispatch(setIndex(lastIndex))

                setFirstRun(false)
            }

            // set source in redux store for Content
            dispatch(setSource(data))

            if (DEBUG) {
                console.log("source", data)
            }
        }
    }, [data, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    // enable/disable stepping left/right executed by TimeSelector
    useEffect(() => {
        if (source && Number.isFinite(index)) {
            var list = source[0]
            var incrementedIndex = index - 1
            var decrementedIndex = index + 1

            if (list?.[incrementedIndex]) {
                setCanStepLeft(true)
            } else {
                setCanStepLeft(false)
            }

            if (list?.[decrementedIndex]) {
                setCanStepRight(true)
            } else {
                setCanStepRight(false)
            }
        }
    }, [source, index])

    useEffect(() => {
        return () => {
            onOpenSideDrawer(false)
            setIsDrawerOpen(false);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps  

    useEffect(() => {
        const current = refSider.current;
        if (!current) return;

        const observer = new ResizeObserver(() => {
            const newWidth = current.clientWidth - accordionOffset; // current sider width without padding of accordion
            setSiderWidth((prevWidth) => (prevWidth !== newWidth ? newWidth : prevWidth)); // only update if the new width is different from the current state
        });

        observer.observe(current);
        return () => observer.disconnect();
    }, []);

    const handleTouchStart = (e) => {
        startYRef.current = e.touches[0].clientY;
        isPullingRef.current = e.target.closest(".puller") !== null;
    }

    const handleTouchMove = (e) => {
        const deltaY = e.touches[0].clientY - startYRef.current;
        
        if (isPullingRef.current && deltaY > 30) {
            toggleBottomDrawer(false)
        }
    }

    useEffect(() => {
        if (footerOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => document.body.style.overflow = "auto";
    }, [footerOpen])

    const controlsAccordion = (
        <Box sx={{ width: "100%" }}>

            {/* diary */}

            {enableDiary && (
                <Accordion
                    expanded={expanded === 'diary'}
                    onChange={onChangeExpanded('diary')}
                    isPortrait={isPortrait}
                >
                    <AccordionSummary>
                        {t("events.construction_diary.construction_diary")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Diary
                            loading={loading}
                            timestamp={timestamp}
                            shouldDisableDate={shouldDisableDate}
                            setTimestamp={updateTimestamp}
                            siderWidth={siderWidth}
                            footerOpen={footerOpen}
                        />
                    </AccordionDetails>
                </Accordion>
            )}

           {/* download custom */}

            {!isMobile && (
                <Accordion
                    expanded={expanded === 'downloadcustom'}
                    onChange={onChangeExpanded('downloadcustom')}
                    isPortrait={isPortrait}
                >
                    <AccordionSummary>
                        {t("archive.download.custom")}
                    </AccordionSummary>

                    <AccordionDetails>
                        <Download 
                            timestamp={timestamp} 
                            siderWidth={siderWidth} 
                            shouldDisableDate={shouldDisableDate}
                            loading={loading}
                        />
                    </AccordionDetails>
                </Accordion>
            )}
            
            {/* download total */}

            {!isMobile && enableDownloadTotal && (
                <Accordion
                    expanded={expanded === 'downloadtotal'}
                    onChange={onChangeExpanded('downloadtotal')}
                    isPortrait={isPortrait}
                >
                    <AccordionSummary>
                        {t("archive.download.total")}
                    </AccordionSummary>

                    <AccordionDetails>
                        <DownloadButton
                            sourceUrl={`${BASEURL}downloads/${PROJECT_FILE_DATE ^ 9102015}.zip`}
                            targetFilename={`${dayjs.unix(PROJECT_FILE_DATE).format("YY-MM-DD")}.zip`}
                            type="zip"
                        />
                    </AccordionDetails>
                </Accordion>
            )}

            {/* archive */}

            <Accordion
                expanded={expanded === 'archive'}
                onChange={onChangeExpanded('archive')}
                isPortrait={isPortrait}
            >
                <AccordionSummary>
                    {t("archive.archive")}
                </AccordionSummary>

                <AccordionDetails>
                    {!isPortrait && (
                        <Box sx={{ justifyContent: "center", display: "flex" }}>
                            <LocalizationProvider 
                                dateAdapter={DayjsAdapter}
                                locale={i18next.language}
                            >
                                <TimePicker
                                    open={pickerOpen}
                                    onClose={() => setPickerOpen(false)}
                                    value={timestamp}
                                    onChange={updateTimestamp}
                                    renderInput={({ inputRef }) => 
                                        <TimeSelector 
                                            stepLeft={stepLeft} 
                                            timestamp={timestamp}
                                            stepRight={stepRight}
                                            loading={loading}
                                            canStepLeft={canStepLeft}
                                            canStepRight={canStepRight}
                                            setPickerOpen={setPickerOpen}
                                            inputRef={inputRef}
                                        />
                                    }
                                    cancelText={t("actions.cancel")}
                                    shouldDisableTime={shouldDisableTime}
                                    showToolbar={false}
                                />
                            </LocalizationProvider>
                        </Box>
                    )}

                    <Calendar 
                        timestamp={timestamp} 
                        setTimestamp={updateTimestamp} 
                        siderWidth={siderWidth} 
                        shouldDisableDate={shouldDisableDate}
                        loading={loading}
                        loadingEvents={loadingEvents}
                        openTagModal={openTagModal}
                        onOpenTagModal={onOpenTagModal}
                        onCloseTagModal={onCloseTagModal}
                        tagData={tagData}
                    />
                </AccordionDetails>
            </Accordion>

        </Box>
    )

    // mobile footer portrait
    if (isMobile && isPortrait) {
        return (
           <Container sx={{ backgroundColor: "black", height: "100%" }}>

                <SwipeableDrawer
                    anchor="bottom"
                    open={footerOpen || animating}
                    onClose={() => toggleBottomDrawer(false)}
                    onOpen={() => toggleBottomDrawer("archive")}
                    swipeAreaWidth={drawerBleeding + 9} // increases the area from which swiping is possible
                    disableSwipeToOpen={true} // prevent opening by swiping because its opened by buttons
                    ModalProps={{ 
                        keepMounted: true, 
                        closeAfterTransition: true, 
                        disableRestoreFocus: true
                    }}
                    PaperProps={{
                        sx: {
                            height: `calc(${footerHeight} - ${drawerBleeding}px - 6px)`, // 6px because otherwise there is white space below second accordion
                            overflow: 'visible',
                            transition: animating ? `transform ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeInOut}` : undefined
                        },
                        onTouchStart: footerOpen ? handleTouchStart : undefined,
                        onTouchMove: footerOpen ? handleTouchMove : undefined
                    }}
                    variant={footerOpen ? "persistent" : "temporary"}
                >
                    <Backdrop 
                        open={footerOpen}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            toggleBottomDrawer(false);
                        }}
                    />

                    <Box
                        sx={{
                            position: 'absolute',
                            top: -footerHeight,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                            backgroundColor: "white"
                        }}
                    >

                        {/* puller component to pull out the drawer */}

                        {footerOpen ? (
                            <Box 
                                className="puller"
                                sx={{
                                    width: "100%",
                                    height: 24,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Box 
                                    sx={{
                                        width: 30,
                                        height: 6,
                                        backgroundColor: grey[300],
                                        borderRadius: 3
                                    }}
                                />
                            </Box>
                        ) : (
                            <Grid 
                                container 
                                columns={{ xs: 1 }} 
                                sx={{ height: footerOpen ? "inherit" : "unset" }}
                            >
                                <Grid 
                                    item 
                                    xs={1} 
                                    sx={{ 
                                        display: "flex", 
                                        justifyContent: "center", 
                                        height: footerOpen ? "inherit" : "unset" 
                                    }}
                                >                                
                                    
                                    <Box sx={{ flexGrow: 1 }}>                        
                                        <Diary 
                                            loading={loading}
                                            timestamp={timestamp}
                                            disabled={!enableDiary}
                                            footerOpen={footerOpen}
                                            setFooterOpen={() => toggleBottomDrawer("diary")}
                                        />
                                    </Box>

                                    <LocalizationProvider 
                                        dateAdapter={DayjsAdapter}
                                        locale={i18next.language}
                                    >
                                        <DateTimePicker
                                            ampm={false}
                                            label={t("date.date_n_time")}
                                            inputFormat={(t("date.format.full_date_time"))}
                                            mask={t("date.mask.full_date_time")}
                                            renderInput={({ inputRef }) =>
                                                <TimeSelector 
                                                    stepLeft={stepLeft}
                                                    timestamp={timestamp} 
                                                    stepRight={stepRight} 
                                                    showDate={true} 
                                                    canStepLeft={canStepLeft}
                                                    canStepRight={canStepRight}
                                                    loading={loading}
                                                    setPickerOpen={setPickerOpen}
                                                    inputRef={inputRef}
                                                />
                                            }
                                            value={timestamp}
                                            onChange={updateTimestamp}
                                            cancelText={t("actions.cancel")}
                                            toolbarTitle={t("date.select_datetime")}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            loading={loading}
                                            shouldDisableDate={shouldDisableDate}
                                            shouldDisableTime={shouldDisableTime}
                                            renderLoading={() => <CalendarPickerSkeleton />}
                                            showTodayButton
                                            todayText={t("date.today")}
                                            open={pickerOpen}
                                            onClose={() => setPickerOpen(false)}
                                        />
                                    </LocalizationProvider>

                                    <Box>
                                        <IconButton
                                            sx={{ p: 1, pointerEvents: "all" }}
                                            aria-label="calendar" 
                                            onClick={() => toggleBottomDrawer("archive")}
                                            color="primary"
                                            disabled={loading}
                                        >
                                            <EventNote sx={{ fontSize: "2rem" }} />
                                        </IconButton>
                                    </Box>
                                </Grid>

                                <Grid 
                                    item 
                                    xs={1} 
                                    zeroMinWidth 
                                    sx={{ height: footerOpen ? "inherit" : "unset" }}
                                >
                                    <Events 
                                        events={events}
                                        footerOpen={footerOpen}
                                        setFooterOpen={() => toggleBottomDrawer("archive")}
                                        preview={true}
                                        loading={loading || loadingEvents}
                                    />
                                </Grid>
                                
                            </Grid>
                        )}

                    </Box>

                    {/* content of the drawer below puller component */}

                    {footerOpen && (
                        <Box 
                            sx={{
                                maxHeight: `calc(${fullHeight} - ${drawerBleeding}px - 6px)`, // 6px because otherwise there is white space below second accordion
                                overflowY: 'auto',
                                width: 'auto',
                                overflowX: 'hidden',
                                mt: "4px"
                            }}
                        >
                            {controlsAccordion}
                        </Box>
                    )}

                </SwipeableDrawer>
            </Container>
        )
        
    // mobile sider landscape
    } else if (isMobile && !isPortrait) {
        return (
            <SwipeableDrawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => toggleSideDrawer(false)}
                onOpen={() => toggleSideDrawer(true)}
                swipeAreaWidth={drawerBleeding + 9} // increases the area from which swiping is possible
                disableSwipeToOpen={false}
                hideBackdrop={true} // prevent the background from graying out outside the sider
                sx={{ pointerEvents: "none" }} // disable invisible layer that prevents interaction with the content (= background of the drawer)
                ModalProps={{ 
                    keepMounted: true, 
                    closeAfterTransition: true, 
                    disableRestoreFocus: true
                }}
                PaperProps={{
                    sx: {
                        width: initialSiderWidth - drawerBleeding,
                        boxSizing: 'border-box',
                        overflow: 'visible',
                        height: `100%`,
                        pointerEvents: "auto" // necessary to remain interaction with the drawer
                    }
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "white",
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                        right: 0,
                        left: -drawerBleeding,
                        visibility: 'visible',
                        overflow: 'auto',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        overflowX: "hidden"
                    }}
                >
                    
                    {/* puller component to pull out the drawer */}

                    <Box 
                        sx={{
                            position: 'absolute',
                            backgroundColor: isDrawerOpen ? '#e0e0e0' : '#003366',
                            height: 30,
                            width: 6,
                            top: `calc(50% + 8px)`,
                            left: 8,
                            borderRadius: 3
                        }} 
                    />

                    {/* content of the drawer below puller component */}
                    
                    <Box
                        sx={{
                            overflow: 'auto',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            mt: `${appbarHeight}px`,
                            ml: `${drawerBleeding}px`,
                            height: "100%",
                            maxHeight: fullHeight
                        }}
                    >
                        {controlsAccordion}
                    </Box>
                </Box>
            </SwipeableDrawer>
        )
        
    // desktop sider
    } else {
        return (
            <Drawer
                variant="permanent"
                anchor="right"
                PaperProps={{
                    sx: {
                        width: initialSiderWidth, 
                        boxSizing: 'border-box'
                    }
                }}
            >
                <Toolbar /> {/* provides top distance without additional functionality */}

                <Box 
                    ref={refSider}
                    sx={{ 
                        overflow: 'auto',
                        display: "flex",
                        flexDirection: "column", 
                        alignItems: "center",
                        overflowX: "hidden"
                    }}
                >
                    {controlsAccordion}
                </Box>
            </Drawer>
        )
    }
}