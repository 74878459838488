import { Box, Button, Typography } from '@mui/material';
import { useWindowDimensions } from './Utilities';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useKeycloak } from './Keycloak';

export default function Status({ code, controls }) {

    const { contentHeight, windowHeight } = useWindowDimensions()
    const { HOME } = window.conf.ROUTES
    const { t } = useTranslation()
    const { doLogout } = useKeycloak()
    
    const backHome = () => navigate(HOME.path)
    const hasControl = (control) => controls?.includes(control)
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: hasControl("header") ? contentHeight : windowHeight
            }}
        >

            <Typography 
                variant="h1" 
                sx={{ 
                    color: 'white',
                    userSelect: 'none'
                }}
            >
                {t("status.sorry")}
            </Typography>

            <Typography 
                variant="h6" 
                sx={{ 
                    color: 'white', 
                    maxWidth: '80%', 
                    textAlign: 'center',
                    userSelect: 'none'
                }}
            >
                {t("status." + code)}
            </Typography>

            {hasControl("back") &&
            <Button 
                variant="contained" 
                sx={{ marginTop: "20px" }} 
                onClick={backHome}
            >
                {t("status.back_home")}
            </Button>}

            {hasControl("logout") &&
            <Button 
                variant="contained" 
                sx={{ marginTop: "20px" }} 
                onClick={doLogout}
            >
                {t("user.logout")}
            </Button>}
            
        </Box>
    )
}