import 'video.js/dist/video-js.css';
import videojs from "video.js";
import de from 'video.js/dist/lang/de.json';
import nl from 'video.js/dist/lang/nl.json';
import i18next from "i18next";
import { LinearProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { checkFile, fetchFile, isMobileApp, saveFile, sendDataToMobileApp } from "../Utilities";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';

if (i18next.language !== 'en') {
    videojs.addLanguage('de', de)
    videojs.addLanguage('nl', nl)
}

const VideoJs = (props) => {

    const { t } = useTranslation()
    const { camera: { timelapse: source = "", name: cameraName = "", id: cameraId = 0 } } = props
    const { CAMERAS, LAYOUT: { WRAP_LIMIT, TITLE }, DEBUG } = window.conf
    
    const [isDownloading, setIsDownloading] = useState(false)

    const videoRef = useRef(null)
    const playerRef = useRef(null)
    const moreThanOneCam = CAMERAS.length > 1
    const manyCams = CAMERAS.length > WRAP_LIMIT
    const camera = cameraName 
        ? `${cameraName}` 
        : moreThanOneCam 
            ? `${t("events.camera")} ${cameraId + 1}` 
            : ""
    const title = TITLE
        ? camera
            ? `${TITLE}_`
            : TITLE
        : ""
    const options = {
        autoplay: false,
        muted: true,
        language: i18next.language,
        controls: true,
        responsive: true,
        preload: isMobile ? "metadata" : "auto",
        nativeControlsForTouch: false, // use custom controls on mobile devices
        sources: [{ src: source }],
        playbackRates: [ 0.5, 1, 1.5, 2 ],
        controlBar: { 'volumePanel': false },
        experimentalSvgIcons: true,
        playsinline: true // indicates to the browser that non-fullscreen playback is preferred when fullscreen playback is the native default, such as in iOS Safari
    }
    const sourceLowQuality = source.replace('timelapsecomplete.mp4', 'timelapsecomplete_reduced.mp4')
    const debug = DEBUG
    const targetFilename = `${title}${camera}`

    const takeSnapshot = (video) => {
        const canvas = document.createElement('canvas')
        const filename = `${targetFilename || t("actions.screenshot")}.jpg`

        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)

        const dataUrl = canvas.toDataURL('image/jpeg', 1.0)

        if (isMobileApp) {
            sendDataToMobileApp(JSON.stringify({ sourceUrl: dataUrl, targetFilename: filename }));
        } else {
            saveFile(dataUrl, filename)
        }
    }

    useEffect(() => {
        let player = playerRef.current;

        if (!player) {

            const videoElement = videoRef.current

            if (!videoElement) return

            player = playerRef.current = videojs(videoElement, options)

            const playerControlBar = player.getChild('ControlBar')
                        
            playerControlBar.addChild('button', {
                controlText: t("actions.screenshot"),
                className: 'vjs-snapshot-button',
                clickHandler: () => takeSnapshot(videoElement)
            }, 13)
            
            playerControlBar.addChild('button', {
                controlText: t("actions.download"),
                className: 'vjs-download-button',
                clickHandler: () => fetchFile(source, `${targetFilename || t("timelapse.total")}.mp4`, setIsDownloading)
            }, 14)

            setupQualitySwitching(player, playerControlBar)

        } else {
            player.src(options.sources);
        }

    }, [source, videoRef]) // eslint-disable-line react-hooks/exhaustive-deps

    const setupQualitySwitching = async(player, playerControlBar) => {

        const { exists, contentType } = await checkFile(sourceLowQuality)

        if (!exists || contentType !== 'video') {
            console.warn("Reduced timelapse not available, quality cannot be changed.");
            return;
        }

        let lastSeekTime = 0;

        const vjsMenuButtonComponent = videojs.getComponent('MenuButton');
        const vjsMenuItemComponent = videojs.getComponent('MenuItem');

        class QualityMenu extends vjsMenuButtonComponent {

            constructor(player, options = {}) {
                super(player, options);

                const textElement = videojs.dom.createEl('span', {
                    className: 'vjs-quality-menu-text',
                    innerHTML: "Auto"
                });

                this.el().appendChild(textElement);
                this.el().title = t("timelapse.quality");

                this.addClass('vjs-quality-menu vjs-playback-rate vjs-menu-button vjs-menu-button-popup vjs-button');
                this.isSwitchingQuality = false;
            }

            createItems() {
                const qualities = [
                    { label: t("timelapse.original"), src: source },
                    { label: t("timelapse.reduced"), src: sourceLowQuality }
                ];
        
                return qualities.map(quality => {
                    const menuItem = new vjsMenuItemComponent(this.player_, {
                        label: quality.label,
                        src: quality.src,
                        selectable: true,
                        className: "vjs-quality-menu-item",
                        selected: quality.label === t("timelapse.original")
                    });
        
                    menuItem.on('click', () => {
                        this.updateMenuSelection(menuItem);
                        this.updateMenuText(menuItem);
                        this.switchQuality(menuItem);
                    });
        
                    return menuItem;
                });
            }

            switchQuality(selectedItem) {
                this.isSwitchingQuality = true;

                const player = this.player_;
                const currentTime = player.currentTime();
                const isPlaying = !player.paused();

                player.src(selectedItem.options_.src);

                player.one('loadeddata', () => {
                    player.currentTime(currentTime);

                    if (isPlaying) {
                        player.play();
                    }

                    this.isSwitchingQuality = false;
                });
            }

            switchQualityToReduced() {
                const lowQualityItem = this.menu.children_.find(item => item.options_.label === t("timelapse.reduced"));
            
                if (lowQualityItem) {
                    this.updateMenuSelection(lowQualityItem);
                    this.updateMenuText(lowQualityItem);
                    this.switchQuality(lowQualityItem);
                }
            }

            updateMenuSelection(selectedItem) {
                this.menu.children_.forEach(item => item.selected(false));
                selectedItem.selected(true);
            }

            updateMenuText(selectedItem) {
                const textElement = this.el().querySelector('.vjs-quality-menu-text');
                if (textElement) {
                    textElement.innerHTML = selectedItem.options_.label;
                }
            }
        }

        videojs.registerComponent('QualityMenu', QualityMenu);

        playerControlBar?.addChild('QualityMenu', {}, 15)

        const qualityMenu = player?.controlBar?.getChild('QualityMenu');

        player?.on("seeking", () => {
            lastSeekTime = Date.now();
        });

        player?.on('waiting', () => {
            const now = Date.now();
            const isManualSeek = now - lastSeekTime < 500;

            if (isManualSeek && debug) {
                console.log("Manual seek detected, quality remains unchanged.");
            }

            if (!isManualSeek && !qualityMenu?.isSwitchingQuality && player.currentSrc()?.includes(source)) {
                debug && console.log("Buffering detected, switching to low quality.");
                qualityMenu.switchQualityToReduced();
            }
        });
    }

    useEffect(() => {
        const player = playerRef.current;
    
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const Progress = () => {
        return (
            <div style={{
                position: 'absolute',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 35
            }}>
                <Typography variant="caption" fontSize={16} color="white">
                    {t("actions.saving")}...
                </Typography>
                <LinearProgress style={{ width: '99%' }} />
            </div>
        )
    }

    return (
        <div style={{
            width: "100%",
            height: manyCams ? "98%" : '100%',
            display: 'flex',
            alignItems: 'center',
        }}>
            <video
                ref={videoRef}
                className="video-js vjs-big-play-centered vjs-full-height"
                crossOrigin="anonymous" // needed for snapshot on local environment
            />
            {isDownloading && <Progress />}
        </div>
    );
}

export default VideoJs;