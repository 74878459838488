import React from 'react';
import VideoJs from './VideoJs';
import Title from "../Title"
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { Box, useMediaQuery, useTheme } from '@mui/material'

export default function Timelapse(props) {
    
    const { breakpoints } = useTheme();
    const { contentHeight, camera, isSideDrawerOpen } = props
    const { isLandscape } = useMobileOrientation();
    
    const camCount = window.conf.CAMERAS.length
    const manyCams = camCount > window.conf.LAYOUT.WRAP_LIMIT
    const moreThanOneCamera = camCount > 1
    const isXl = useMediaQuery(breakpoints.up('xl'))
    const isMd = useMediaQuery(breakpoints.down('md'))

    return (
        <Box 
            sx={{
                display: "flex",
                alignContent: 'center',
                justifyContent: "center",
                position: "relative"
            }} 
            className={
                !isMobileOnly
                ? camCount < 2
                    ? ''
                    : camCount === 2
                        ? 'height-2'
                        : 'height'
                : '' 
            }
        >
            <Title cameraName={camera.name} />
            <Box 
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 
                        isMobileOnly
                        ? isLandscape
                            ? isSideDrawerOpen && moreThanOneCamera
                                ? "unset"
                                : contentHeight
                            : camCount < 2
                                ? contentHeight
                                : camCount < 3
                                    ? contentHeight / 2
                                    : 'unset'
                        : manyCams
                            ? "unset"
                            : isXl && camCount > 2
                                ? contentHeight/Math.ceil(camCount/3) - 8
                                : camCount === 1
                                    ? contentHeight/Math.ceil(camCount/2)
                                    : contentHeight/Math.ceil(camCount/2) - 8,
                    width: manyCams ? "unset" : "100%",
                    border: "none",
                    padding: moreThanOneCamera && !isMobile && !isMd ? "0.2rem" : "0",
                }}
                className={
                    !isMobileOnly
                    ? camCount < 2
                        ? ''
                        : camCount === 2
                            ? 'height-2'
                            : 'height'
                    : '' 
                }
            >
                <VideoJs camera={camera} />
            </Box>
        </Box>
    )
}