import React, { useState, useEffect } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Container, Button } from '@mui/material';
import { MenuOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '../Keycloak';
import { checkFile } from '../Utilities';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import Weather from "./Weather"
import Settings from './Settings';
import Stats from './Stats';

export default function Header() {

    const { t } = useTranslation()
    const { ROUTES: { HOME, LIVE, TIMELAPSE, ARCHIVE }, LAYOUT: { LOGO, LOGO_FRAME, INVERTED_COLORS, TITLE }, STATS } = window.conf
    const { isLoggedIn, isUsersPortal } = useKeycloak()
    const { isPortrait } = useMobileOrientation()

    const navigate = useNavigate()
    const currentPath = window.location.pathname
    const pages = [
        { name: t("pages.live"), ...HOME },
        { name: t("pages.timelapse"), ...TIMELAPSE },
        { name: t("pages.archive"), ...ARCHIVE }
    ]
    const statsVisible = isLoggedIn() && isUsersPortal()

    const [anchorElNav, setAnchorElNav] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(currentPath === LIVE.path ? 0 : pages.findIndex(page => page.path === currentPath))
    const [invertColors, setInvertColors] = useState(window.localStorage.getItem('netco_color') === "true" ? true : false || INVERTED_COLORS)
    const [logo, setLogo] = useState(null)
    
    useEffect(() => {
        if (currentPath !== pages[selectedIndex]?.path) {
            setSelectedIndex(currentPath === LIVE.path ? 0 : pages.findIndex(page => page.path === currentPath))
        }
    }, [currentPath, selectedIndex]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const checkLogo = async() => {
            const { exists, contentType } = await checkFile(LOGO)
            if (exists && contentType === 'image') {
                setLogo(LOGO)
            }
        }
        checkLogo()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenNavMenu = (event) => {
        if (anchorElNav !== event?.currentTarget) {
            setAnchorElNav(event.currentTarget)
        }
    }

    const handleCloseNavMenu = () => {
        if (anchorElNav) {
            setAnchorElNav(null)
        }
    }

    const handleSelectMenuItem = (event, index) => {
        if (selectedIndex !== index) {
            setSelectedIndex(index)
            navigate(pages[index].path)
        }
        handleCloseNavMenu()
    }

    const toggleInvertColors = () => {
        var toggledColors = !invertColors
        setInvertColors(toggledColors)
        window.localStorage.setItem('netco_color', toggledColors)
    }
    
    return (
        <AppBar 
            position="sticky" 
            sx={{ 
                zIndex: theme => isPortrait ? theme.zIndex.drawer - 1 : theme.zIndex.drawer + 1, 
                backgroundColor: invertColors ? 'primary.main' : 'secondary.main'
            }}
        >
            <Container maxWidth={false}>
                <Toolbar 
                    disableGutters
                    variant={isMobile ? 'dense' : "regular"}
                >

                    {/* logo */}

                    <Box 
                        sx={{ 
                            flexGrow: 0, 
                            display: logo ? { xs: 'none', sm: 'flex' } : "none"
                        }}
                    >
                        <div style={{ 
                            backgroundColor: logo && LOGO_FRAME ? 'white' : 'transparent', 
                            padding: 4,
                            display: 'flex'
                        }}> 
                            <img
                                src={logo}
                                alt='logo'
                                height='32px'
                            />
                        </div>
                    </Box>
                    
                    {/* title */}

                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ 
                            flexGrow: 1,
                            pr: "8px",
                            pl: { sm: logo ? '16px' : 0, md: logo ? '16px' : "8px" },
                            userSelect: "none",
                            fontSize: { xs: '1rem', sm: '1.33rem' },
                            color: invertColors ? 'white' : 'primary.main'
                        }}
                    >
                        {TITLE}
                    </Typography>
                    
                    {/* weather */}

                    <Weather />

                    {/* statistics */}

                    {STATS.ENABLED && (
                        <Stats 
                            invertColors={invertColors}
                            isVisible={statsVisible}
                        />
                    )}

                    {/* desktop menu */}

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, index) => (
                            <Button
                                key={index}
                                disabled={page.disabled}
                                onClick={event => handleSelectMenuItem(event, index)}
                                sx={{
                                    textAlign: "center",
                                    display: 'block',
                                    color: index === selectedIndex
                                        ? invertColors
                                            ? 'white'
                                            : 'primary.main'
                                        : invertColors
                                            ? 'secondary.dark'
                                            : 'dimgray',
                                    whiteSpace: 'nowrap',
                                    fontSize: '1rem',
                                }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    {/* mobile menu */}

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        
                        <IconButton
                            onClick={handleOpenNavMenu}
                            sx={{ 
                                p: 0, 
                                color: invertColors ? 'white' : 'primary.main'
                            }}
                        >
                            <MenuOutlined sx={{
                                fontSize: "1.75rem"
                            }}/>
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            {pages.map((page, index) => (
                                <MenuItem 
                                    key={index}
                                    disabled={page.disabled}
                                    onClick={event => handleSelectMenuItem(event, index)}
                                    selected={index === selectedIndex}
                                    component="a"
                                >
                                    {page.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/* settings */}

                    <Settings 
                        invertColors={invertColors}
                        toggleInvertColors={toggleInvertColors}
                    />

                </Toolbar>
            </Container>
        </AppBar>
    )
}