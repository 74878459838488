import React, { useState, useEffect, useMemo } from 'react';
import { Add, PlaylistAdd } from '@mui/icons-material';
import { Alert, Box, Button, Dialog, DialogContent, Fab, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { useRemoveTagMutation, useAddTagsMutation } from '../../api/graphqlApi';
import { LoadingButton } from '@mui/lab';
import { getData, setEvents } from "../../api/dataSlice"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import FormField from './FormField';

export default function AddTag({ updateSearch, timestamp, loading, data, openTagModal, onOpenTagModal, onCloseTagModal, disabled }) {

    const { t } = useTranslation()
    const { events, source, index, overViewCameraId, checkedArchivePictures } = useSelector(getData)
    const { CAMERAS, DEBUG } = window.conf

    const defaultSelectedCamera = useMemo(() => Number.isFinite(overViewCameraId) ? [CAMERAS[overViewCameraId]] : CAMERAS, [overViewCameraId, CAMERAS])

    const [selectedCameras, setSelectedCameras] = useState(defaultSelectedCamera)
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [disableTagOkBtn, setDisableTagOkBtn] = useState(true);
    const [addTags, { data: dataAdd, isLoading: loadingAdd, isSuccess: successAdd, error: errorAdd }] = useAddTagsMutation()
    const [removeTag, { isLoading: loadingRemove, isSuccess: successRemove, error: errorRemove }] = useRemoveTagMutation()

    const loadingButton = loading
    const loadingContent = loadingAdd || loadingRemove
    const dispatch = useDispatch()
    const hasDataFromModal = data && !data.target
    const isSelectedMultiple = checkedArchivePictures.length > 1
    const formattedTimestamps =
        isSelectedMultiple && !hasDataFromModal
            ? checkedArchivePictures.map(obj => dayjs(obj.timestamp).format(t('date.format.full_date_time'))).join(', ')
            : dayjs(timestamp).format(t('date.format.full_date_time'))
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const handleCameraChange = (event) => {
        const value = event.target.value
        if (value?.length) { // change only when at least one camera was selected
            setSelectedCameras(value)
            if (selectedCameras.length > 0)
                setDisableTagOkBtn(false)
            setIsError(false)
        }
    };

    const handleCategoryChange = (event) => {
        const value = event.target.value
        setCategory(value);
        setDisableTagOkBtn(false)
        setIsError(false)
    };

    const handleDescriptionChange = (event) => {
        const value = event.target.value
        setDescription(value);
        setDisableTagOkBtn(false)
        setIsError(false)
    }

    const onAddTag = () => {

        if (!category || !description) {
            return
        }    

        var archivePicturesIds = 
            hasDataFromModal
                ? [source[0]?.[index]?.[1]?.[selectedCameras[0].id]?.id]
                // ? isSelectedMultiple
                //     ? [data.archivePicturesId] // although multiple selected, only the archivePicturesId from edited event (modal) is taken (must be added to addTags response!)
                //     : [source[0]?.[index]?.[1]?.[selectedCameras[0].id]?.id]
                : isSelectedMultiple
                    ? checkedArchivePictures?.map(archivePicture => archivePicture.archivePictureId)
                    : selectedCameras.map(camera => source[0]?.[index]?.[1]?.[camera.id]?.id)

        var newTags = {
            id: hasDataFromModal ? data.id : 0, // use existing id from edited event (modal) or 0
            archivePicturesIds: archivePicturesIds,
            cameras: isSelectedMultiple || hasDataFromModal ? selectedCameras[0] : selectedCameras,
            category: category,
            description: description
        }

        if (DEBUG) {
            console.log("addTags", newTags)
        }

        addTags({ data: newTags })
        
        setDisableTagOkBtn(true)
    }

    const onRemoveTag = () => {
        if (data && !data?.target) {
            removeTag({ id: data.id })
        }
        setDisableTagOkBtn(true)
    }
 
    const onCancel = () => {
        onCloseTagModal()
        setIsError(false)
        setDisableTagOkBtn(true)
    }

    useEffect(() => {
        if (selectedCameras.length === 0 || hasDataFromModal) { // true if no cam seleceted or moadal opend for edit and yet no field is touched
            setDisableTagOkBtn(true)
        } else {
            setDisableTagOkBtn(false)
        }
    }, [selectedCameras, hasDataFromModal])

    useEffect(() => {
        if (hasDataFromModal) {
            setSelectedCameras([data.camera])
            setCategory(data.category)
            setDescription(data.description)
        } else {
            setSelectedCameras(defaultSelectedCamera)
            setCategory("")
            setDescription("")
        }
    }, [data, hasDataFromModal, CAMERAS, defaultSelectedCamera])

    useEffect(() => {
        if (successAdd) {

            updateSearch(true)
            
            let data = isSelectedMultiple && !hasDataFromModal
                ? dataAdd.filter(tag => tag.archivePicturesId === source[0]?.[index]?.[1]?.[selectedCameras[0].id]?.id)?.[0] // only the event whose archivePictureId is same as of the selected event should be added to events
                : dataAdd

            if (data) {
                if (isSelectedMultiple && !hasDataFromModal) {
                    dispatch(setEvents(updateEvents(data, events, -1)))
                } else {
                    let eventsUpdated = [...events]
                    data.forEach((tag, index) => eventsUpdated = updateEvents(tag, eventsUpdated, index))
                    dispatch(setEvents(eventsUpdated))
                }
               
                onCloseTagModal()
            } else {
                setErrorMsg(t("msg.exe_error"))
                setIsError(true)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successAdd])

    const updateEvents = (tag, events, index) => {

        if (!tag) {
            return events
        }

        var eventIndexToEdit = events.findIndex(event => event?.id === tag?.id)  // check if event exists
        
        if (eventIndexToEdit >= 0) {
            var eventsWithEdit = [...events]

            eventsWithEdit[eventIndexToEdit] = {
                id: tag.id,
                camera: index === -1 ? selectedCameras[0] : selectedCameras[index],
                category: category,
                description: description,
                archivePicturesId: tag.archivePicturesId
            }

            return eventsWithEdit
        } else {
            var eventsWithNew = [...events, {
                id: tag.id,
                camera: index === -1 ? selectedCameras[0] : selectedCameras[index],
                category: category,
                description: description,
                archivePicturesId: tag.archivePicturesId
            }]

            return eventsWithNew
        }
    }

    useEffect(() => {
        if (successRemove && hasDataFromModal) {
            var eventsWithoutRemoved = events.filter(elem => elem.id !== data.id)
            dispatch(setEvents(eventsWithoutRemoved))
            onCloseTagModal()
            updateSearch(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successRemove])

    useEffect(() => {
        if (errorRemove || errorAdd) {
            const errorStr = errorRemove?.message || errorAdd?.message
            setErrorMsg(errorStr.substring(0, errorStr.indexOf('.') + 1))
            setIsError(true)
        }
    }, [errorRemove, errorAdd])

    const buttonStyle = { 
        width: 100, 
        mr: 1, 
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
    
    return (
        <Box>
            <Tooltip title={t('events.add_new_event')} placement="top" arrow>
                <Box component="span">
                    <Fab 
                        area-label="Add Tag"
                        size="small"
                        color="primary"
                        onClick={onOpenTagModal}
                        disabled={loadingButton || disabled}
                        sx={{ ml: '4px' }}
                    >
                        {isSelectedMultiple
                        ? <PlaylistAdd />
                        : <Add />}
                    </Fab>
                </Box>
            </Tooltip>
            
            <Dialog
                open={openTagModal}
                onClose={onCancel}
                aria-labelledby="add-tag-dialog-title"
            >
                <DialogContent
                    sx={{
                        minWidth: 250,
                        maxWidth: 300,
                        p: 2
                    }}
                >
                    <Grid container>

                        <Grid item xs={12} sx={{ pb: 1 }} /> {/* An empty grid item. A trick to fix the lable layout issue on FormControl */}
                        
                        {CAMERAS.length > 1 && (
                            <Grid item xs={12} sx={{ pb: 2 }}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id='selectCameraLabel'>{t("events.camera")}</InputLabel>
                                    <Select
                                        multiple
                                        labelid='selectCameraLabel'
                                        label={t("events.camera")}
                                        value={selectedCameras}
                                        onChange={handleCameraChange}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                    width: 300,
                                                }
                                            }
                                        }}
                                        // inputProps={{ readOnly: data?.category === t("weather.weather") }}
                                        // sx={{
                                        //     '& .MuiSelect-select': {
                                        //         cursor: 
                                        //             loadingContent || isSelectedMultiple || hasDataFromModal
                                        //             ? "default"
                                        //             : data?.category === t("weather.weather") 
                                        //                 ? "default" 
                                        //                 : "pointer",
                                        //     }
                                        // }}                  
                                        disabled={loadingContent || isSelectedMultiple || hasDataFromModal || data?.category === t("weather.weather")}
                                    >
                                        {CAMERAS.map(camera => (
                                            <MenuItem
                                                key={camera.id}
                                                value={camera}
                                            >
                                                {camera.name !== "" ? camera.name : `${t("events.camera")} ${camera.id + 1}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id='imageTimeStamp'></InputLabel>
                                <TextField
                                    labelid='imageTimeStamp'
                                    id='imageTimeStamp'
                                    label={t("date.date_n_time")}
                                    value={formattedTimestamps}
                                    key="imageTimeStamp"
                                    // sx={{
                                    //     '& .MuiOutlinedInput-root': {
                                    //         '& :hover': {
                                    //             cursor: "default"
                                    //         }
                                    //     }
                                    // }}
                                    disabled
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <FormField
                                id="selectCategory"
                                label={t("events.category")}
                                required
                                value={category}
                                onChange={handleCategoryChange}
                                inputProps={{ readOnly: data?.category === t(`weather.weather`) }}
                                disabled={loadingContent}
                                key="selectCategoryDropdown"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& :hover': {
                                            cursor: data?.category === t(`weather.weather`) ? "default" : "text"
                                        }
                                    }
                                }}
                                multiline
                                rows={1}
                                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <FormField
                                id="descriptionTextarea"
                                label={t("events.description")}
                                required
                                multiline
                                rows={4}
                                value={description}
                                onChange={handleDescriptionChange}
                                inputProps={{ readOnly: data?.category === t(`weather.weather`) }}
                                disabled={loadingContent}
                                key="descriptionTextarea"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& :hover': {
                                            cursor: data?.category === t(`weather.weather`) ? "default" : "text"
                                        }
                                    }
                                }}
                            />
                        </Grid>

                        {isError && (
                            <Box sx={{
                                marginBottom: 15,
                                width: "100%"
                            }}>
                                <Alert variant="filled" severity="warning" sx={{ alignItems: "center" }}>
                                    {errorMsg}
                                </Alert>
                            </Box>
                        )}

                        <Grid 
                            item 
                            xs={12}
                            sx={{
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-between'
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{ ...buttonStyle }}
                                onClick={onCancel}
                                disabled={loadingContent}
                            >
                                {t("actions.cancel")}
                            </Button>

                            {data && 
                            !data?.target && (
                                <LoadingButton
                                    variant="outlined"
                                    sx={{ ...buttonStyle }}
                                    disabled={loadingAdd || loadingAdd || data?.category === t(`weather.weather`)}
                                    loading={loadingRemove}
                                    onClick={onRemoveTag}
                                >
                                    {t("actions.delete")}
                                </LoadingButton>
                            )}

                            <LoadingButton
                                variant="outlined"
                                sx={{ ...buttonStyle, mr: 0 }}
                                disabled={loadingRemove || (CAMERAS.length > 1 && !selectedCameras) || !category || !description || data?.category === t(`weather.weather`) || disableTagOkBtn || isError}
                                loading={loadingAdd || loadingAdd}
                                onClick={onAddTag}
                            >
                                {t("actions.add")}
                            </LoadingButton>
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>

        </Box>
    )
}