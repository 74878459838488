import React, { useEffect, useRef, useState } from 'react';
import { Container, SwipeableDrawer, Box, Toolbar, Drawer, Backdrop, useTheme } from '@mui/material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getData, setIndex, setInitialFooterHeight, setSource, setTimestamp } from '../../api/dataSlice';
import { useGetDataByCameraQuery } from '../../api/graphqlApi';
import { useWindowDimensions } from '../Utilities';
import Form from './Form';

export default function Controls({ initialSiderWidth, onOpenSideDrawer, setPaddingFooter, drawerBleeding }) {

    const { isPortrait } = useMobileOrientation();
    const { source, index } = useSelector(getData);
    const { data, isLoading: loading } = useGetDataByCameraQuery(null, { pollingInterval: 600000 });
    const { DEBUG } = window.conf;
    const { appbarHeight } = useWindowDimensions();

    const fullHeight = isPortrait ? "100vh" : `calc(100vh - ${appbarHeight}px)`
    const dispatch = useDispatch();
    const footerWrapperRef = useRef(null);
    const startYRef = useRef(0);
    const isPullingRef = useRef(false);
    const theme = useTheme();

    const [footerOpen, setFooterOpen] = useState(false);
    const [firstRun, setFirstRun] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [footerHeight, setFooterHeight] = useState(fullHeight);
    const [animating, setAnimating] = useState(false);

    const toggleSideDrawer = (open) => {
        setIsDrawerOpen(open);
        onOpenSideDrawer(open);

        if (open) {
            document.body.style.overflow = 'auto'; // keep background (content) scrollable
        } 
    };

    const toggleBottomDrawer = (open) => {
        if (open) {
            const contentHeight = footerWrapperRef.current?.offsetHeight;
            setFooterHeight(`${contentHeight}px`);
            setFooterOpen(true);
        } else {
            setAnimating(true);

            setTimeout(() => {
                setFooterOpen(false);
                setAnimating(false);
            }, theme.transitions.duration.leavingScreen);
        }
    };

    const updateTimestamp = (timestamp) => {
        dispatch(setTimestamp(timestamp));
    };

    useEffect(() => {
        if (index) {
            updateTimestamp(source?.[0]?.[index]?.[0]);
        }
    }, [index]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data) {
            if (firstRun) {
                var list = data[0];
                var lastIndex = list.length - 1;
                var lastElement = list[lastIndex];

                updateTimestamp(lastElement?.[0]); // set time in TimeSelector for display clock by date of last image (ONLY ONCE!)
                dispatch(setIndex(lastIndex)); // set index in redux store for Controls
                setFirstRun(false);
            }

            dispatch(setSource(data)); // set source in redux store for Content

            DEBUG && console.log('source', data);
        }
    }, [data, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isMobile) {
            dispatch(setInitialFooterHeight(16));
        } else {
            dispatch(setInitialFooterHeight(0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    // check footerWrapperRef resize
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { height } = entry.contentRect;
                const outerHeight = height + drawerBleeding + 8;
                setFooterHeight(`${outerHeight}px`);
            }
        });

        if (footerWrapperRef.current) {
            resizeObserver.observe(footerWrapperRef.current);
        }
        
        return () => resizeObserver.disconnect();
    }, [footerOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPaddingFooter(drawerBleeding)
    }, [drawerBleeding, setPaddingFooter])

    useEffect(() => {
        return () => {
            onOpenSideDrawer(false)
            setIsDrawerOpen(false);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps  

    const handleTouchStart = (e) => {
        startYRef.current = e.touches[0].clientY;
        isPullingRef.current = e.target.closest(".puller") !== null;
    }

    const handleTouchMove = (e) => {
        const deltaY = e.touches[0].clientY - startYRef.current;
        
        if (isPullingRef.current && deltaY > 30) {
            toggleBottomDrawer(false)
        }
    }

    useEffect(() => {
        if (footerOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => document.body.style.overflow = "auto";
    }, [footerOpen])

    // mobile footer portrait
    if (isMobile && isPortrait) {
        return (
            <Container sx={{ backgroundColor: 'black', height: '100%' }}>

                <SwipeableDrawer
                    anchor="bottom"
                    open={footerOpen || animating}
                    onClose={() => toggleBottomDrawer(false)}
                    onOpen={() => toggleBottomDrawer(true)}
                    swipeAreaWidth={drawerBleeding + 9} // increases the area from which swiping is possible
                    disableSwipeToOpen={false}
                    ModalProps={{ 
                        keepMounted: true, 
                        closeAfterTransition: true, 
                        disableRestoreFocus: true
                    }}
                    PaperProps={{
                        sx: {
                            height: `calc(${footerHeight} - ${drawerBleeding}px - 6px)`, // 6px because otherwise there is white space below second accordion
                            overflow: 'visible',
                            transition: animating ? `transform ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeInOut}` : undefined
                        },
                        onTouchStart: footerOpen ? handleTouchStart : undefined,
                        onTouchMove: footerOpen ? handleTouchMove : undefined,
                    }}
                    variant={footerOpen ? "persistent" : "temporary"}
                >
                    <Backdrop 
                        open={footerOpen}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            toggleBottomDrawer(false);
                        }}
                    />

                    <Box
                        sx={{
                            position: 'absolute',
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                            backgroundColor: 'white'
                        }}
                    >

                        {/* puller component to pull out the drawer */}

                        <Box 
                            className="puller"
                            sx={{
                                width: "100%",
                                height: 24,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Box
                                sx={{
                                    width: 30,
                                    height: 6,
                                    backgroundColor: footerOpen ? '#e0e0e0' : '#003366',
                                    borderRadius: 3
                                }}
                            />                      
                        </Box>

                        {/* content of the drawer below puller component */}

                        <Box
                            sx={{
                                maxHeight: `calc(${fullHeight} - ${drawerBleeding}px - 6px)`, // 6px because otherwise there is white space below second accordion
                                overflowY: 'auto',
                                width: 'auto',
                                overflowX: 'hidden'
                            }}
                            ref={footerWrapperRef}
                        >
                            <Form loading={loading} />
                        </Box>

                    </Box>

                </SwipeableDrawer>

            </Container>
        )

    // mobile sider landscape
    } else if (isMobile && !isPortrait) {
        return (
            <SwipeableDrawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => toggleSideDrawer(false)}
                onOpen={() => toggleSideDrawer(true)}
                swipeAreaWidth={drawerBleeding + 9} // increases the area from which swiping is possible
                disableSwipeToOpen={false}
                hideBackdrop={true} // prevent the background from graying out outside the sider
                sx={{ pointerEvents: "none" }} // disable invisible layer that prevents interaction with the content (= background of the drawer)
                ModalProps={{ 
                    keepMounted: true, 
                    closeAfterTransition: true, 
                    disableRestoreFocus: true
                }}
                PaperProps={{
                    sx: {
                        width: initialSiderWidth - drawerBleeding,
                        boxSizing: 'border-box',
                        height: `100%`,
                        overflow: 'visible',
                        pointerEvents: "auto" // necessary to remain interaction with the drawer
                    }
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "white",
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                        right: 0,
                        left: -drawerBleeding,
                        visibility: 'visible',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        overflowX: 'hidden',
                    }}
                >

                    {/* puller component to pull out the drawer */}

                    <Box
                        sx={{
                            position: 'absolute',
                            backgroundColor: isDrawerOpen ? '#e0e0e0' : '#003366',
                            height: 30,
                            width: 6,
                            top: `calc(50% + 8px)`,
                            left: 8,
                            borderRadius: 3
                        }}
                    />
                    
                    {/* content of the drawer below puller component */}

                    <Box
                        sx={{
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: `${appbarHeight}px`,
                            ml: `${drawerBleeding}px`,
                            height: '100%',
                            maxHeight: fullHeight,
                            width: "100%"
                        }}
                    >
                        <Form loading={loading} />
                    </Box>

                </Box>
            </SwipeableDrawer>
        )

    // desktop sider
    } else {
        return (
            <Drawer
                variant="permanent"
                anchor="right"
                PaperProps={{
                    sx: {
                        width: initialSiderWidth, 
                        boxSizing: 'border-box'
                    }
                }}
            >
                <Toolbar /> {/* provides top distance without additional functionality */}
                
                <Box
                    sx={{
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowX: 'hidden',
                        height: '100%',
                        maxHeight: fullHeight
                    }}
                >
                    <Form loading={loading} />
                </Box>
            </Drawer>
        )
    }
}
