import dayjs from 'dayjs';
import { createSlice } from '@reduxjs/toolkit'
import { getAdjustedIndexAndTimestamp } from '../components/Utilities';

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        source: null,
        index: null,
        overViewCameraId: null,
        events: [],
        initialFooterHeight: 48,
        timestamp: dayjs(),
        archiveProgress: 0,
        checkedArchivePictures: [],
        token: null
    },
    reducers: {
        setSource: (state, { payload }) => {
            state.source = payload;
            if (payload) {
                const timestamp = payload[0]?.[state.index]?.[0];
                let indexAndTimestamp = getAdjustedIndexAndTimestamp(payload, timestamp)
                state.index = indexAndTimestamp.index;
                state.timestamp = indexAndTimestamp.timestamp;
            }
        },
        setIndex: (state, { payload }) => {
            state.index = payload;
        },
        setTimestamp: (state, { payload }) => {
            const dayJsFromDate = dayjs(payload)
            if (!state.timestamp.isSame(dayJsFromDate)) {
                state.timestamp = dayJsFromDate
                if (state.source && payload) {
                    let indexAndTimestamp = getAdjustedIndexAndTimestamp(state.source, dayJsFromDate)
                    state.index = indexAndTimestamp.index;
                    state.timestamp = indexAndTimestamp.timestamp;
                }
            }
        },
        setOverviewCameraId: (state, { payload }) => {
            state.overViewCameraId = payload;
        },
        setEvents: (state, { payload }) => {
            state.events = payload
        },
        setInitialFooterHeight: (state, { payload }) => {
            state.initialFooterHeight = payload
        },
        loadPrevImage: (state) => {
            const decrementedIndex = state.index - 1;
            if (state.source[0]?.[decrementedIndex]){
                state.index = decrementedIndex;
                state.timestamp = dayjs(state.source[0][decrementedIndex][0]);
            }
        },
        loadNextImage: (state) => {
            const incrementedIndex = state.index + 1;
            if (state.source[0]?.[incrementedIndex]){
                state.index = incrementedIndex;
                state.timestamp = dayjs(state.source[0][incrementedIndex][0]);
            }
        },
        setArchiveProgress: (state, { payload }) => {
            state.archiveProgress = payload
        },
        setCheckedArchivePictures: (state,  { payload } ) => {
            const type = payload.type
            const action = payload.action
            if (type === 'clean') {
                state.checkedArchivePictures = []
            } else if (type === 'add') {
                state.checkedArchivePictures.push(action.value)
            } else if (type === 'remove') {
                state.checkedArchivePictures.splice(action.index, 1)
            }
        },
        setToken: (state, { payload }) => {
            window.history.replaceState({}, document.title, window.location.pathname + window.location.search) // remove hash from URL
            state.token = payload
        }
    }
})

export const {
    setSource, 
    setIndex,
    setTimestamp,
    setOverviewCameraId,
    setEvents,
    setInitialFooterHeight,
    loadPrevImage,
    loadNextImage,
    setArchiveProgress,
    setCheckedArchivePictures,
    setToken
} = dataSlice.actions

export const getData = state => state.data

export default dataSlice.reducer