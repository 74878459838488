import React, { useState } from 'react';
import LiveCameraSwitch, { ViewType } from './StaticLiveSwitch';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { Box, CircularProgress, LinearProgress, Typography, useTheme, useMediaQuery } from '@mui/material';
import { fetchFile } from '../Utilities';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import dayjs from "dayjs"
import Title from "../Title"
import Controlbar from '../Controlbar';
import Image from '../Image';

export default function Static(props) {

    const { breakpoints } = useTheme();
    const { t } = useTranslation()
    const { contentHeight, camera: { image: imageSource = "", name: cameraName = "", id: cameraId = 0, video: hasStream = false }, handleStaticLiveSwitch } = props
    const { isLandscape } = useMobileOrientation()
    const { ROUTES: { LIVE }, CAMERAS, LAYOUT: { WRAP_LIMIT, REFRESH_MIN, TITLE } } = window.conf

    const [zoom, setZoom] = useState(1)
    const [isDownloading, setIsDownloading] = useState(false);
    const [imageError, setImageError] = useState(false)

    const fullscreenHandle = useFullScreenHandle()
    const camCount = CAMERAS.length
    const moreThanOneCam = camCount > 1
    const manyCams = camCount > WRAP_LIMIT
    const refresh = REFRESH_MIN * 60000
    const navigate = useNavigate()
    const currentPath = window.location.pathname
    const isXl = useMediaQuery(breakpoints.up('xl'))
    const currentDatetime = dayjs().format("YY-MM-DD_HH-mm")
    const title = TITLE ? `${TITLE}_` : ""
    const camera = cameraName 
        ? `${cameraName}_` 
        : moreThanOneCam 
            ? `${t("events.camera")} ${cameraId + 1}_` 
            : ""
    const targetFilename = `${title}${camera}${currentDatetime}` || t("actions.screenshot")

    // disables panning when image is not zoomed in or panned
    const PanningInteractionHandler = (instance, state) => {
        if (state?.positionX >= 0 && state?.positionY >= 0 && state?.scale <= 1) {
            instance.setup.panning.disabled = true;
        } else {
            instance.setup.panning.disabled = false;
        }
    }

    return (
        <TransformWrapper
            disabled={!Boolean(imageSource) || imageError}
            disablePadding // removes paddable border around the content
            onZoomStop={ref => PanningInteractionHandler(ref.instance, ref.state)}
            onPanning={ref => PanningInteractionHandler(ref.instance, ref.state)}
            onInit={ref => ref.instance.setup.panning.disabled = true}
            onTransformed={ref => setZoom(ref.state.scale)}
        >
            {(utils) => (
                <FullScreen
                    handle={fullscreenHandle}
                    onChange={() => utils.resetTransform()}
                >
                    <Title cameraName={cameraName} />

                    {hasStream && (
                        <LiveCameraSwitch
                            type={ViewType.Streaming} 
                            onClick={() => {
                                // if user is not on the live page then redirect to the live page else switch the view without redirection
                                if (currentPath !== LIVE.path) {
                                    navigate(LIVE.path)
                                } else {
                                    handleStaticLiveSwitch()
                                }
                            }} 
                        />
                    )}

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: fullscreenHandle.active
                                ? "100%"
                                : isMobileOnly
                                    ? isLandscape
                                        ? contentHeight
                                        : camCount < 2
                                            ? contentHeight
                                            : camCount < 3
                                                ? contentHeight/2
                                                : 'unset'
                                    : manyCams
                                        ? 'unset'
                                        : isXl && camCount > 2
                                            ? contentHeight/Math.ceil(camCount/3)
                                            : contentHeight/Math.ceil(camCount/2)
                        }}
                        className={ 
                            isMobile 
                            ? "" 
                            : camCount < 2
                                ? ''
                                : camCount === 2
                                    ? 'height-2'
                                    : 'height'
                        }
                    >
                        <TransformComponent>

                            <Image 
                                source={imageSource} 
                                cursor={zoom > 1 ? 'move' : 'default'} 
                                refresh={refresh}
                                setImageError={setImageError}
                            />
                            
                            {!imageSource && (
                                <CircularProgress 
                                    sx={{ position: 'absolute' }} 
                                    disableShrink 
                                    size={60} 
                                    color="secondary"
                                />
                            )}

                        </TransformComponent>
                    </Box>
                
                    <Controlbar 
                        cameraName={cameraName} 
                        zoom={zoom}
                        fullscreenHandle={fullscreenHandle} 
                        zoomUtils={utils}
                        disabled={!Boolean(imageSource) || imageError}
                        imageSource={imageSource}
                        handleTakeSnapshot={() => fetchFile(imageSource, `${targetFilename}.jpg`, setIsDownloading)}
                    />
                    
                    {isDownloading && (
                        <Box sx={{
                            position: 'absolute',
                            width: '99%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bottom: 35
                        }}>
            
                            <Typography 
                                variant="caption" 
                                fontSize={16} 
                                color="white"
                            >
                                {t("actions.saving")}...
                            </Typography>
            
                            <LinearProgress style={{ width: '100%' }} />
                        </Box>
                    )}

                </FullScreen>
            )}
        </TransformWrapper>
    )
}