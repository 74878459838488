import dayjs from 'dayjs';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import { Typography, IconButton, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile, useMobileOrientation } from 'react-device-detect';

export default function TimeSelector({ stepLeft, timestamp, stepRight, showDate, loading, canStepLeft, canStepRight, setPickerOpen, inputRef, originalValueRef }) {

    const { t, i18n } = useTranslation()
    const { isPortrait } = useMobileOrientation()

    var dateTime = timestamp ?? dayjs()
    var hourMinute = dayjs(dateTime).format("H:mm").toString()
    var dayMonth = dayjs(dateTime).locale(i18n.language).format(t("date.format.shortest")).toString()

    function handleClick() {
        if (setPickerOpen) {
            setPickerOpen(true)
        }
    }

    return (
        <Box sx={{
            flexGrow: isMobile && isPortrait ? 1 : 0,
            display: "flex",
            alignItems: "center"
        }}>                        
            <IconButton 
                sx={{ p: 0, pr: 1, pointerEvents: "all" }}
                aria-label="stepleft"
                onClick={stepLeft}
                color="primary"
                disabled={loading || !canStepLeft}
            >
                <ChevronLeft 
                    sx={{ fontSize: showDate ? "3rem" : "2.4rem" }} 
                />
            </IconButton>

            <Button 
                component="span"
                sx={{ 
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 0, 
                    color: "black", 
                    minWidth: 0,
                    pointerEvents: "auto"
                }} 
                onClick={handleClick}
                ref={inputRef}
                disabled={loading}
            >
                {showDate
                ? <>
                    <Typography className="TimeSelector-label">
                        {hourMinute}
                    </Typography>
                    <Typography className="TimeSelector-label">
                        {dayMonth}
                    </Typography>
                </>
                : <Typography 
                    className="TimeSelector-label" 
                    sx={{ 
                        fontSize: "1.6rem !important",
                        cursor: "pointer"
                    }}
                >
                    {hourMinute}
                </Typography>}
                
            </Button>

            <IconButton 
                sx={{ p: 0, pl: 1, pointerEvents: "all" }}
                aria-label="stepright"
                onClick={stepRight}
                color="primary"
                disabled={loading || !canStepRight}
            >
                <ChevronRight 
                    sx={{ fontSize: showDate ? "3rem" : "2.4rem" }} 
                />
            </IconButton>
        </Box>
    )
}