import React, { useState } from 'react';
import { Stack, Box, TextField, Divider } from '@mui/material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { LocalizationProvider, DateTimePicker, CalendarPickerSkeleton } from '@mui/lab';
import { getData } from "../../api/dataSlice"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import Events from './Events'
import DayjsAdapter from '@mui/lab/AdapterDayjs';
import dayjs from 'dayjs';
import AddTag from './AddTag';
import SearchTag from './SearchTag';
import i18next from 'i18next';
import DatePicker from '../DatePicker';

export default function Calendar(props) {
    
    const { LAYOUT: { TAGGING } } = window.conf
    const { t } = useTranslation()
    const { tagData, openTagModal, onOpenTagModal, onCloseTagModal, siderWidth, timestamp, setTimestamp, loadingEvents, shouldDisableDate, loading, shouldDisableTime } = props
    const { source, events } = useSelector(getData)
    const { isPortrait } = useMobileOrientation()

    const [searchDataUpdated, setSearchDataUpdated] = useState(false);
    
    const minDate = dayjs(source?.[0]?.[0]?.[0])
    const maxDate = dayjs(source?.[0]?.[source[0].length-1]?.[0])
    const disableTaggingButtons = !TAGGING

    return (
        <>
            <LocalizationProvider 
                dateAdapter={DayjsAdapter}
                locale={i18next.language}
            >
                {isMobile && isPortrait ? (
                    <DateTimePicker
                        ampm={false}
                        label={t("date.date_n_time")}
                        inputFormat={(t("date.format.full_date_time"))}
                        mask={t("date.mask.full_date_time")}
                        renderInput={props => <TextField {...props} sx={{ width: "100%" }} />}
                        value={timestamp}
                        onChange={setTimestamp}
                        cancelText={t("actions.cancel")}
                        toolbarTitle={t("date.select_datetime")}
                        minDate={minDate}
                        maxDate={maxDate}
                        loading={loading}
                        shouldDisableDate={shouldDisableDate}
                        shouldDisableTime={shouldDisableTime}
                        renderLoading={() => <CalendarPickerSkeleton />}
                        showTodayButton
                        todayText={t("date.today")}
                    />
                ) : (
                    <DatePicker
                        timestamp={timestamp}
                        setTimestamp={setTimestamp}
                        loading={loading}
                        shouldDisableDate={shouldDisableDate}
                        siderWidth={siderWidth}
                        pt={2}
                    />
                )}
            </LocalizationProvider>

            <Stack 
                sx={{ pt: 2, flexGrow: 1 }} 
                spacing={2}
            >

                <Divider sx={{ height: "11px", pb: "11px", userSelect: "none" }}>
                    {t("events.events")}
                </Divider>

                <Events 
                    events={events}
                    onOpenTagModal={onOpenTagModal}
                    loading={loading || loadingEvents}
                />

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'flex-end',
                    margin: 0,
                    padding: 0,
                    marginTop: 8
                }}>

                    <SearchTag
                        loading={loading}
                        minDate={minDate}
                        maxDate={maxDate}
                        timestamp={timestamp}
                        onOpenTagModal={onOpenTagModal}
                        isSearchUpdated={searchDataUpdated}
                        setSearchUpdated={setSearchDataUpdated}
                        disabled={disableTaggingButtons}
                    />

                    <AddTag
                        loading={loading}
                        openTagModal={openTagModal}
                        onOpenTagModal={onOpenTagModal}
                        onCloseTagModal={onCloseTagModal}
                        data={tagData}
                        timestamp={timestamp}
                        updateSearch={setSearchDataUpdated}
                        disabled={disableTaggingButtons}
                    />
                </Box>

            </Stack>
        </>
    )
}